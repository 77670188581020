.page {

  &__heading-box {
    box-sizing: border-box;
    margin: 30px auto 0 auto;
    max-width: 1280px;
    padding: 30px 0;
    min-height: 1px;
    zoom: 1;
  }
  &__heading {
    font-size: 5rem;
    line-height: 1.3em;
    margin-bottom: 10px;

    &--sub {
      color: $font-color;
      font-size: 1.4rem;
      line-height: 1.3em;
    }
  }
}
@mixin sans-serif {
  font-family: din-2014, ryo-gothic-plusn, sans-serif;
}

$font-color: #666;

$pc: 1024px;
$tab: 769px;
$sp: 768px;

@mixin pc {
  @media (min-width: ($pc)) {
    @content;
  }
}
@mixin tab {
  @media (min-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

html {
  font-size: 62.5%;
  visibility: hidden;
}

html.wf-active,
html.loading-delay {
    visibility: visible;
}

body {
  position: relative;
  z-index: 0;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.8;
  padding: 0;
  margin: 0;
  @include sans-serif;

}

.zoom-img {
  overflow: hidden;

  img {
    display: block;
    transition-duration: 0.3s;

    &:hover {
      opacity: 0.8;
      transform: scale(1.1);
      transition-duration: 0.3s;
    }
  }

}

.header {
  height: 112px;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ddd;

  &__inner {
    z-index: 100;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0 1rem;
    height: 112px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }

  &__column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    height: 112px;
    min-height: 1px;
    zoom: 1;
  }

  &__logo {
    a {
      color: $font-color;
      text-decoration: none;
    }
    img,source {
      width: 100%;
      height: 100%;
    }
  }

  &__nav {

    @include sp {
      position: fixed;
      z-index : 2;
      top  : 10rem;
      left : 0;
      background: #fff;
      text-align: center;
      transform: translateX(100%);
      transition: all 0.6s;
      width: 100%;

      &.active {
        transform: translateX(0%);
      }
    }

    &-group {
      display: table;
      padding-top: 42px;
      height: 70px;
      min-height: 1px;
      zoom: 1;

      @include sp {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 100%;
      }

      > dt {
        @include sp {
          display: block;
          height: auto;
        }

        display: table-cell;
        vertical-align: bottom;
        height: 70px;

        a {
          color: $font-color;
          text-decoration: none;
          display: block;
          padding: 10px;
          height: auto;
          text-align: center;
          line-height: 1rem;

          @include sp {
            background: #fff;
            display: block;
            padding: 1em 0;
            text-decoration :none;
          }
        }
      }
      > dd {
        display: none;
        overflow: hidden;
        position: absolute;
        height: auto;

        .active {
          z-index: 100;
          position: absolute;
          top: 112px;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

.burger {
  @include tab {
    display: none;
  }
  @include sp {
    position: relative;
    width: 33px;
    padding: 1.5em 0;
    cursor: pointer;
    z-index: 100;

    > span {
      position: absolute;
      left: 0;
      display: inline-block;
      box-sizing: border-box;
      width: 100%;
      height: 3px;
      border-radius: 3px;
      background: #111;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;


      &:nth-of-type(1) {
        top: 0;
      }

      &:nth-of-type(2) {
        top: 30%;
      }

      &:nth-of-type(3) {
        top: 60%;
      }
    }

    &.active > span:nth-of-type(1) {
      -webkit-transform: translateY(9px) rotate(-315deg);
      transform: translateY(9px) rotate(-315deg);
    }

    &.active > span:nth-of-type(2) {
      opacity: 0;
    }

    &.active > span:nth-of-type(3) {
      -webkit-transform: translateY(-13px) rotate(315deg);
      transform: translateY(-13px) rotate(315deg);
    }
  }
}

.scroll-prevent {
  overflow: hidden;
}

.footer {
  position: relative;
  padding: 30px 0;
  margin-top: 13rem;
  background-color: #000;

  @include sp {
    margin-top: 5rem;
  }

  &__copy {

    &-text {
      text-align: center;
      font-size: 0.86rem;
      line-height: 1.3em;
      color: #fff;
    }
  }
}

.mv {
  &__list {
    padding: 0;
    margin: 0;

    > li {
      list-style-type: none;
      padding: 0;
    }
  }
  &__image {
    display: block;
    position: relative;
    width: 100%;
    line-height: 0;
    img {
      width: 100%;

    }
  }
}

.container {
  box-sizing: border-box;
  margin: 30px auto 0 auto;
  max-width: 1280px;
  padding: 0 30px;
  min-height: 1px;
  zoom: 1;
}

.about {

  &__table {
    width: 100%;

    th {
      font-size: 1.4rem;
      border-bottom: solid 1px #ddd;
      color: $font-color;
      padding: 20px;
      text-align: left;
      vertical-align: top;
      word-wrap: break-word;

      @include sp {
        font-size: 1.2rem;
        padding: 1.2rem;
      }
    }
    td {
      font-size: 1.5rem;
      border-bottom: solid 1px #ddd;
      padding: 20px;
      text-align: left;
      font-weight: bold;
      vertical-align: top;
      word-wrap: break-word;

      @include sp {
        padding: 1.2rem;
        font-size: 1.3rem;
      }
    }
  }

  &__overview-list {
    padding: 0;
    margin: 0;

    > li {
      list-style-type: none;
      line-height: 1.5;
      padding: 0;
    }
  }
}

.contact {

  &__outer {

  }
  .contact-form {

    &__input-list {
      padding: 0;
      margin: 0;

      > li {
        width: 70%;
        margin: auto;
        list-style-type: none;
        font-size: 1.4rem;
        border-bottom: solid 1px #ddd;
        padding: 20px;
        text-align: left;
        font-weight: bold;
        vertical-align: top;
        word-wrap: break-word;

        @include sp {
          font-size: 1.2rem;
          width: 100%;
          padding: 1.2rem;
        }

        input,select,textarea {
          margin: 4px;
          padding: 1rem;
          height: auto;
          vertical-align: middle;
          outline: none;
          border: 1px solid #cccccc;
          background: #ffffff;
          font-size: 100%;
          width: 100%;
          border-radius: 2px;

          @include sp {
            padding: 0.5rem;
          }
        }
      }
    }

    &__button-box {
      margin-top: 3rem;
      text-align: center;
    }
    &__button {
      background-color: #000;
      color: #fff;
      width: 20rem;
      padding: 1rem 2rem;
      height: auto;
      font-weight: bold;
      margin: 0 10px;

      @include pc {
        font-size: 1.8rem;
      }
    }
    &__list-head {
      margin-right: 1rem;
    }
  }

  .required-badge {
    color: #f30;
  }
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

@import "_home";
@import "_page";
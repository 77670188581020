.home {
  &__heading {
    color: $font-color;
    font-size: 2.14rem;
    line-height: 1.3em;
    padding: 1rem 3rem 0;

    @include sp {
      padding: 1rem 0;
    }
  }

  &__summary-box {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 3rem 0;
    margin-bottom: 5rem;
  }

  &__lead {
    color: $font-color;
    font-size: 1.4rem;
  }

  &__news {

    &-list {
      display: table;
      width: 100%;

      > dl {
        display: table-row;
        @include sp {
          display: block;
        }

        > dt {
          display: table-cell;
          padding: 15px 5px 15px 3rem;
          width: 23rem;
          font-size: 1.4rem;
          font-weight: bold;
          color: #000;
          min-height: 1px;
          zoom: 1;

          @include sp {
            display: block;
            padding: 0;
          }
        }

        > dd {
          display: table-cell;
          padding: 15px 15px 15px 5px;
          font-size: 1.4rem;
          line-height: 1.6em;

          @include sp {
            display: block;
            margin: 0;
          }

          a {
            color: #666;
            text-decoration: none;
          }
        }
      }
    }
  }

  &__column {
    width: 100%;
    display: flex;
    margin-top: 30px;
    min-height: 1px;
    zoom: 1;
    padding: 0;

    @include sp {
      flex-direction: column;
    }

    &--summary {
      justify-content: space-evenly;
      justify-items: flex-start;

      > li:first-child {
        flex-basis: 40%;
      }
      > li:last-child {
        padding: 1.2rem;
      }
    }

    > li {
      list-style-type: none;
      padding: 0;
      flex-basis: 50%;

      figure {
        padding: 0;
        margin: 0;

        a {
          overflow: hidden;
          display: block;
          line-height: 0;
          backface-visibility: hidden;
          color: #666;
          text-decoration: none;
          margin: 0;
          padding: 0;
          font-size: 100%;
          vertical-align: baseline;
          background: transparent;
        }
        img {
          width: 100%;
          height: auto;
          display: block;
          opacity: 1;
          -webkit-transform: scale(1, 1);
          -moz-transform: scale(1, 1);
          -ms-transform: scale(1, 1);
          -webkit-transition: all 0.2s ease-out;
          margin: 0;
          padding: 0;
          border: none;
        }
      }
    }
  }
}

.news-badge {
  display: inline-block;
  box-sizing: border-box;
  margin: 4px 0 4px 2rem;
  min-width: 50px;
  padding: 0 5px;
  height: 13px;
  line-height: 13px;
  text-align: center;
  font-size: 10px;
  font-weight: normal;
  color: #fff;
  background-color: #999;
}

.link-more {
  display: inline-block;
  background-color: #000;
  color: #fff;
  padding: 0.5rem 2rem;
  height: auto;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 10px;

  a {
    color: #fff;
    text-decoration: none;
  }
}